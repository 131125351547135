import React from "react"
import { Link,graphql } from "gatsby"

import Layout from "../components/layout"
import BreadCrumb from "../components/breadcrumb"
import Img from "gatsby-image"


const About = ({pageContext,data}) => {
  var metainfo = {
  title: pageContext.metainfo? (pageContext.metainfo.title || 'Σχετικά με εμάς') + " | " + process.env.SITE_NAME  : (pageContext.title || 'Σχετικά με εμάς') + " | " + process.env.SITE_NAME,
  description:pageContext.metainfo? pageContext.metainfo.description || '' : '',
  image:'/images/logo.png'
}
  return (
    <Layout metainfo={metainfo}>
    <BreadCrumb title={pageContext.title}/>

    <div id="about" className="about-main-block theme-2">
  <div className="container">
    <div className="row">
  <div className="col-lg-6 col-md-12">
    <div className="section text-left">
      <h1 className="section-heading">25+ Χρόνια Εμπειρίας<br />στον κλάδο των μεταφορών</h1>
      <p>Έχοντας πολυετή εμπειρία, σύγχρονο στόλο οχημάτων, εξειδικευμένο και με τεχνογνωσία προσωπικό, ανταποκρινόμαστε στις ανάγκες κάθε μετακόμισης.</p>
    </div>
    <div className="about-dtl">
      <div className="row">
        <div className="col-lg-2">
          <div className="about-icon">
            <img src="/images/icons/about-01.png" className="img-fluid" alt="about-img" />
          </div>
        </div>
        <div className="col-lg-10">
          <div className="about-sub-heading">
            <h4 className="about-heading">Μετακομίσεις</h4>
            <p>Αναλαμβάνουμε μετακομίσεις οικιών, γραφείων και εταιρειών πανελλαδικά.</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-2">
          <div className="about-icon">
            <img src="/images/icons/about-02.png" className="img-fluid" alt="about-img" />
          </div>
        </div>
        <div className="col-lg-10">
          <div className="about-sub-heading">
            <h4 className="about-heading">Αποθηκεύσεις</h4>
            <p>Παρέχουμε άριστες αποθηκευτικές υπηρεσίες για μικρό ή μεγάλο χρονικό διάστημα.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 text-right">
    <div className="about-img_wd">
      <Img fluid={data.aboutexp.edges[0].node.childImageSharp.fluid}
          alt={`about1`}
/>
    </div>
  </div>
</div>



  </div>





</div>

<div className="process-area section-padding">
<div className="container">
<div className="row">
  <div className="col-lg-12 text-center">
    <h1 className="section-heading">Πως Εργαζόμαστε</h1>
  </div>
</div>
<div className="row">
  <div className="col-lg-4 col-md-12 col-sm-12">
    <div className="single-process-item">
      <div className="about-points-icon">
        <img src="/images/icons/apply-001.png" className="img-fluid" alt="home-icon" />
      </div>
      <div className="about-point-heading">ΒΗΜΑ 1</div>
      <h5>Καταγράφουμε τα Αντικείμενα</h5>
      <div className="number">01</div>
    </div>
  </div>
  <div className="col-lg-4 col-md-12 col-sm-12">
    <div className="single-process-item">
      <div className="about-points-icon">
        <img src="/images/icons/apply-002.png" className="img-fluid" alt="home-icon" />
      </div>
      <div className="about-point-heading">ΒΗΜΑ 2</div>
      <h5>Πακετάρουμε & Φορτώνουμε</h5>
      <div className="number">02</div>
    </div>
  </div>
  <div className="col-lg-4 col-md-12 col-sm-12">
    <div className="single-process-item">
      <div className="about-points-icon">
        <img src="/images/icons/apply-003.png" className="img-fluid" alt="home-icon" />
      </div>
      <div className="about-point-heading">ΒΗΜΑ 3</div>
      <h5>Μεταφέρουμε με ασφάλεια</h5>
      <div className="number">03</div>
    </div>
  </div>
</div>
</div>
</div>

<div className="chooseus-area section-padding" style={{backgroundImage: 'url("images/bg/clients.jpg")'}}>
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <h1 className="section-heading">Γιατί να μας επιλέξετε</h1>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-5 col-md-12">
        <div className="single-feature-item">
          <div className="num">01</div>
          <div className="feature-content">
            <h5>Έμπειρο Προσωπικό</h5>
            <p>Το εξειδικευμένο προσωπικό μας έρχεται στο χώρο σας, πακετάρει και μεταφέρει με προσοχή όλες τις οικοσκευές σας.</p>
          </div>
        </div>
        <div className="single-feature-item">
          <div className="num">02</div>
          <div className="feature-content">
            <h5>Ταχύτητα</h5>
            <p>Εργαζόμαστε άμεσα έχοντας πάντα πρώτο μέλημα μας την ασφαλή μεταφορά των αντικειμένων.</p>
          </div>
        </div>
        <div className="single-feature-item">
          <div className="num">03</div>
          <div className="feature-content">
            <h5>Ιδανική σχέση Ποιότητας / Τιμής</h5>
            <p>Διατηρούμε το κόστος μετακόμισης χαμηλά ώστε να είναι λογικό έξοδο χωρίς όμως να θυσιάσουμε την ποιότητα στις υπηρεσίες μας.</p>
          </div>
        </div>
      </div>
      <div className="col-lg-7 col-md-12">
        <div className="feature-image">
          <Img fluid={data.aboutwhy.edges[0].node.childImageSharp.fluid}
              alt={`about2`}
    />
        </div>
      </div>
    </div>
  </div>
</div>






    </Layout>
  )
}

export default About



export const query = graphql`
  query {

    aboutexp:allFile(filter: {name: {in: ["about_25_experience"]}}) {
   edges {
     node {
       childImageSharp {
         fluid(maxWidth: 500, maxHeight: 500,quality:80) {
           ...GatsbyImageSharpFluid
         }
       }
     }
   }
 }


   aboutwhy:allFile(filter: {name: {in: ["about_why_us"]}}) {
   edges {
     node {
       childImageSharp {
         fluid(maxWidth: 500, maxHeight: 400,quality:80) {
           ...GatsbyImageSharpFluid
         }
       }
     }
   }
 }


  }
`
